import React from "react";

const Count = ({ count, add }) => {
  return (
    <div>
      <h1>{count}</h1>
    </div>
  );
};

export default Count;
