import React from "react";

const Grass = () => {
  return (
    <>
      {" "}
      <div style={{ height: "83%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "79%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "83%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "90%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "85%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "87%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "79%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "86%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "85%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "88%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "87%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "89%", width: 4, backgroundColor: "green" }} />
      <div style={{ height: "84%", width: 4, backgroundColor: "green" }} />
    </>
  );
};

export default Grass;
